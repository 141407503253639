::-webkit-scrollbar {
  width: 0;
  height: 0;
}

::-webkit-scrollbar-thumb {
  background: none;
}

.DraggablePlane {
  z-index: inherit;
  -webkit-user-select: none;
  user-select: none;
  width: 0;
  height: 0;
  font-family: Lexend Deca, Inter, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  position: relative;
}

.DraggablePlane .aircraftImage {
  z-index: inherit;
  -webkit-user-select: none;
  user-select: none;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.DraggablePlane.selected .aircraftImage {
  outline: 2px solid #286df0;
}

.DraggablePlane.selected.padded .aircraftImage {
  outline-offset: 20px;
}

.DraggablePlane .originIndicator {
  -webkit-user-select: none;
  user-select: none;
  pointer-events: none;
  position: absolute;
}

.DraggablePlane .originIndicator > span {
  pointer-events: none;
  position: relative;
}

.DraggablePlane .origin {
  -webkit-user-select: none;
  user-select: none;
  pointer-events: none;
  position: absolute;
}

.DraggablePlane .origin > span {
  pointer-events: none;
  position: relative;
}

.DraggablePlane .frontWheels {
  z-index: inherit;
  width: 0;
  height: 0;
  position: absolute;
}

.DraggablePlane .frontWheels > span {
  cursor: grab;
  background-color: #3c6ce980;
  border-radius: 50%;
  width: 22px;
  height: 22px;
  display: block;
  position: relative;
  top: -11px;
  left: -11px;
}

.DraggablePlane .frontWheels.tenant > span {
  background-color: #23967f80;
}

.DraggablePlane.dragging .frontWheels > span {
  cursor: grabbing;
}

.DraggablePlane .frontWheelDragIndicator {
  pointer-events: none;
  position: absolute;
}

.DraggablePlane .frontWheelDragIndicator > span {
  pointer-events: none;
}

.DraggablePlane .aircraftDragIndicator {
  pointer-events: none;
  position: absolute;
}

.DraggablePlane .aircraftDragIndicator > span {
  pointer-events: none;
}

.DraggablePlane .frontWheels .wheelAngle {
  transform-origin: 0 0;
  pointer-events: none;
  border-left: 3px dashed #0000;
  height: 40px;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
}

.DraggablePlane .frontWheels.extremeAngle .wheelAngle {
  border-left: 3px dashed #0000;
}

.DraggablePlane.frontWheelDragging .frontWheels .wheelAngle {
  display: block;
}

.DraggablePlane .frontWheels .wheelAngleRight {
  pointer-events: none;
  display: none;
  top: 2px;
}

.DraggablePlane .frontWheels .wheelAngleRearRight {
  top: 2px;
}

.DraggablePlane .frontWheels .unlimitedWheelAngle {
  transform-origin: 0 0;
  pointer-events: none;
  border-left: 3px dashed #00f;
  height: 40px;
  display: none;
  position: absolute;
  top: 0;
  left: -1px;
}

.DraggablePlane .PlanePolygon {
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
}

.DraggablePlane .PlanePolygon polygon {
  pointer-events: fill;
}

.DraggablePlane .PlanePolygon > polygon {
  cursor: grab;
}

.DraggablePlane.readOnly .PlanePolygon > polygon {
  cursor: pointer;
}

.DraggablePlane.dragging .PlanePolygon > polygon {
  cursor: grabbing;
}

.DraggablePlane.selected {
  z-index: 4000;
}

.DraggablePlane.selected .selectionBox {
  position: absolute;
}

.DraggablePlane .tailNumber {
  z-index: 4000;
  text-align: center;
  display: none;
  position: absolute;
}

.DraggablePlane.selected .tailNumber {
  pointer-events: none;
  display: block;
}

.DraggablePlane.forceShow .tailNumber {
  pointer-events: none;
  display: block !important;
}

.DraggablePlane .tailNumber > span {
  display: inline-block;
  position: relative;
  top: -10px;
  left: -50%;
}

.DraggablePlane .tailNumber > span > span {
  color: #fff;
  pointer-events: auto;
  background-color: #286ff1;
  border-radius: 20px;
  min-width: 20px;
  min-height: 11.5px;
  padding: 4px 8px;
  font-size: 12px;
  display: inline-block;
  position: relative;
}

.noteTooltip {
  visibility: hidden;
  color: #000;
  opacity: 0;
  pointer-events: auto;
  -webkit-user-select: text;
  user-select: text;
  cursor: text;
  background-color: #f2f2f2;
  border-radius: 5px;
  width: 117px;
  padding: 5px 0;
  font-size: 12px;
  position: relative;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 100000 !important;
}

.forceDisplayNotes .tailNumber.note > span > span {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  width: 100px;
  margin-bottom: -4px;
}

.tailNumber.note .noteTooltip {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.note > span > span {
  background-color: #b89230 !important;
}

.DraggablePlane.forceDisplayNotes .note {
  display: block !important;
}

.note.tailNumber > span > span:hover ~ .noteTooltip, .noteTooltip:hover {
  visibility: visible !important;
  opacity: 1 !important;
}

.noteTooltip:hover + span {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  width: 100px;
  margin-bottom: -4px;
}

.note.tailNumber > span > span:hover {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  width: 100px;
}

.DraggablePlane .note.tailNumber > span:hover > span {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  width: 100px;
  margin-bottom: -4px;
}

.forceDisplayNotes .noteTooltip {
  visibility: visible !important;
  opacity: 1 !important;
}

.DraggablePlane .hotkey {
  z-index: 4000;
  text-align: center;
  width: 0;
  height: 0;
  display: none;
  position: absolute;
}

.DraggablePlane .rotationHandle {
  width: 0;
  height: 0;
  display: none;
  position: absolute;
}

.DraggablePlane.selected .rotationHandle {
  display: block;
}

.DraggablePlane .rotationHandle > span {
  background-color: #fff;
  border: 2px solid #286ff1;
  width: 6px;
  height: 6px;
  display: block;
  position: relative;
}

.DraggablePlane .rotationHandle.ne {
  top: 0;
  right: 0;
}

.DraggablePlane .rotationHandle.ne > span {
  top: -6px;
  left: -4px;
}

.DraggablePlane .rotationHandle.nw {
  top: 0;
  left: 0;
}

.DraggablePlane .rotationHandle.nw > span {
  top: -6px;
  left: -6px;
}

.DraggablePlane .rotationHandle.se {
  bottom: 0;
  right: 0;
}

.DraggablePlane .rotationHandle.se > span {
  top: -4px;
  left: -4px;
}

.DraggablePlane .rotationHandle.sw {
  bottom: 0;
  left: 0;
}

.DraggablePlane.padded .rotationHandle.se {
  bottom: -20px;
  right: -20px;
}

.DraggablePlane.padded .rotationHandle.sw {
  bottom: -20px;
  left: -20px;
}

.DraggablePlane.padded .rotationHandle.ne {
  top: -20px;
  right: -20px;
}

.DraggablePlane.padded .rotationHandle.nw {
  top: -20px;
  left: -20px;
}

.DraggablePlane .rotationHandle.sw > span {
  top: -4px;
  left: -6px;
}

.DraggablePlane .rotationHandleHitbox {
  cursor: grab;
  width: 50px;
  height: 50px;
  display: none;
  position: absolute;
}

.DraggablePlane.padded .rotationHandleHitbox {
  width: 10px;
  height: 10px;
}

.DraggablePlane.rotating .rotationHandleHitbox {
  cursor: grabbing;
}

.DraggablePlane.selected .rotationHandleHitbox {
  display: block;
}

.DraggablePlane .rotationHandleHitbox.ne {
  top: -25px;
  right: -25px;
}

.DraggablePlane .rotationHandleHitbox.se {
  bottom: -25px;
  right: -25px;
}

.DraggablePlane .rotationHandleHitbox.sw {
  bottom: -25px;
  left: -25px;
}

.DraggablePlane .rotationHandleHitbox.nw {
  top: -25px;
  left: -25px;
}

.DraggablePlane .ClockwiseArrow, .DraggablePlane .AntiClockwiseArrow {
  display: none;
  position: absolute;
}

.DraggablePlane.selected.rotating.rotatingClockwise .ClockwiseArrow, .DraggablePlane.selected.rotating.rotatingAntiClockwise .AntiClockwiseArrow {
  display: block;
}

.DraggablePlane .ClockwiseArrow.ne, .DraggablePlane .AntiClockwiseArrow.ne {
  top: -24px;
  right: -24px;
  transform: rotate(45deg);
}

.DraggablePlane .ClockwiseArrow.se, .DraggablePlane .AntiClockwiseArrow.se {
  bottom: -24px;
  right: -24px;
  transform: rotate(135deg);
}

.DraggablePlane .ClockwiseArrow.sw, .DraggablePlane .AntiClockwiseArrow.sw {
  bottom: -24px;
  left: -24px;
  transform: rotate(225deg);
}

.DraggablePlane .ClockwiseArrow.nw, .DraggablePlane .AntiClockwiseArrow.nw {
  top: -24px;
  left: -24px;
  transform: rotate(315deg);
}

.DraggablePlane-SpacingBufferAircraftContainer {
  pointer-events: none;
  z-index: 1;
  position: absolute;
}
/*# sourceMappingURL=index.a0ee911a.css.map */
