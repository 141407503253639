
.DraggablePlane {
  z-index: inherit;
  position: relative;
  user-select: none;
  width: 0;
  height: 0;

  font-family: Lexend Deca,Inter,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
}

.DraggablePlane .aircraftImage {
  z-index: inherit;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  user-select: none;
}



.DraggablePlane.selected .aircraftImage {
  outline: 2px solid #286df0;
}

.DraggablePlane.selected.padded .aircraftImage {
  outline-offset: 20px;
}

.DraggablePlane .originIndicator {
  position: absolute;
  user-select: none;
  pointer-events: none;
  
}
.DraggablePlane .originIndicator>span {
  pointer-events: none;
  position: relative;
  /* Comment these in for debugging */
  /*left: -5px;
  top: -5px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: red;
  display: block;*/
}

.DraggablePlane .origin {
  position: absolute;
  user-select: none;
  pointer-events: none;
}

.DraggablePlane .origin>span {
  pointer-events: none;
  position: relative;
  /* Comment these in for debugging */
  /*left: -5px;
  top: -5px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: pink;
  display: block;*/
}

.DraggablePlane .frontWheels {
  z-index: inherit;
  position: absolute;
  width: 0;
  height: 0;
}

.DraggablePlane .frontWheels>span {
  position: relative;
  left: -11px;
  top: -11px;
  display: block;
  background-color: #3c6ce980;

  cursor: grab;
  /* 22px is arbitrary from designer */
  height: 22px;
  width: 22px;
  border-radius: 50%;
}
  
.DraggablePlane .frontWheels.tenant>span {
  background-color: #23967F80;
}

.DraggablePlane.dragging .frontWheels>span {
  cursor: grabbing;
}


.DraggablePlane .frontWheelDragIndicator {
  position: absolute;
  pointer-events: none;
}

.DraggablePlane .frontWheelDragIndicator>span {
  pointer-events: none;
  /* Use for debugging */
  /*position: relative;
  left: -5px;
  top: -5px;
  display: block;
  background-color: purple;
  height: 10px;
  width: 10px;
  border-radius: 50%;*/
}

.DraggablePlane .aircraftDragIndicator {
  position: absolute;
  pointer-events: none;
}

.DraggablePlane .aircraftDragIndicator>span {
  pointer-events: none;
  /* Use for debugging */
  /*position: relative;
  left: -5px;
  top: -5px;
  display: block;
  background-color: yellow;
  height: 10px;
  width: 10px;
  border-radius: 50%;*/
}

.DraggablePlane .frontWheels .wheelAngle {
  display: none;
  height: 40px;
  /* border-left: 3px dashed blue; */
  border-left: 3px dashed transparent;
  position: absolute;
  left: 0;
  top: 0;
  transform-origin: 0 0;
  pointer-events: none;
}

.DraggablePlane .frontWheels.extremeAngle .wheelAngle {
  /* border-left: 3px dashed red; */
  border-left: 3px dashed transparent;
}

.DraggablePlane.frontWheelDragging .frontWheels .wheelAngle {
  display: block;
}


.DraggablePlane .frontWheels .wheelAngleRear {
  /*height: 40px;
  border-left: 3px dashed orange;
  position: absolute;
  left: 0;
  top: 0;
  transform-origin: 0 0;
  pointer-events: none;*/
}

.DraggablePlane .frontWheels .wheelAngleRight {
  display: none;
  /* This ensures the angle aligns with the left angle, because of the way
     it uses the border property, it is out of alignment when rotated */
  top: 2px;
  pointer-events: none;
}

.DraggablePlane .frontWheels .wheelAngleRearRight {
  top: 2px;
}

.DraggablePlane .frontWheels .unlimitedWheelAngle {
  display: none;
  height: 40px;
  border-left: 3px dashed blue;
  position: absolute;
  left: -1;
  top: 0;
  transform-origin: 0 0;
  pointer-events: none;
}

.DraggablePlane .PlanePolygon {
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
}

.DraggablePlane .PlanePolygon polygon {
  pointer-events: fill;
}

.DraggablePlane .PlanePolygon>polygon {
  cursor: grab;
}
.DraggablePlane.readOnly .PlanePolygon>polygon {
  cursor: pointer;
}

.DraggablePlane.dragging .PlanePolygon>polygon {
  cursor: grabbing; 
}

.DraggablePlane.selected {
  z-index: 4000;
}

.DraggablePlane.selected .selectionBox {
  position: absolute;
}


.DraggablePlane .tailNumber {
  z-index: 4000;
  display: none;
  position: absolute;
  /* width: 0;
  height: 0; */
  text-align: center;
}

.DraggablePlane.selected .tailNumber {
  display: block;
  pointer-events: none;
}

.DraggablePlane.forceShow .tailNumber {
  display: block !important;
  pointer-events: none;
}

.DraggablePlane .tailNumber>span {
  display: inline-block;
  position: relative;
}


.DraggablePlane .tailNumber>span {
  left: -50%;
  top: -10px;
}
.DraggablePlane .tailNumber>span>span {
  background-color: #286ff1;
  display: inline-block;
  /* left: -50%; */
  /* top: -10px; */
  color: white;
  position: relative;
  font-size: 12px;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 20px;
  pointer-events: auto;
  min-width: 20px; 
  min-height: 11.5px;
}

.noteTooltip {
  visibility: hidden;
  position: relative;
  width: 117px;
  background-color: #f2f2f2;
  font-size: 12px;
  color: #000000;
  border-radius: 5px;
  padding: 5px 0;
  z-index: 100000 !important;
  top: 0px;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  pointer-events: auto;
  user-select: text; /* Make text selectable */
  cursor: text;
}

.forceDisplayNotes .tailNumber.note>span>span {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  width: 100px;
  margin-bottom: -4px;
}

.tailNumber.note .noteTooltip {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.note>span>span {
  background-color: #b89230 !important;
}

.DraggablePlane.readOnly .noteTooltip {
  /* display: none !important; */
}

.DraggablePlane.forceDisplayNotes .note {
  display: block !important;
}

.note.tailNumber>span>span:hover ~ .noteTooltip {
  visibility: visible !important;
  opacity: 1 !important;
}

.noteTooltip:hover {
  visibility: visible !important;
  opacity: 1 !important;
}

.noteTooltip:hover + span {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  margin-bottom: -4px;
  width: 100px;
}

.note.tailNumber>span>span:hover {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  width: 100px; 
}

.DraggablePlane .note.tailNumber>span:hover > span {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  margin-bottom: -4px;
  width: 100px;
}


.forceDisplayNotes .noteTooltip {
  visibility: visible !important;
  opacity: 1 !important;
}

.DraggablePlane .hotkey {
  z-index: 4000;
  display: none;
  position: absolute;
  width: 0;
  height: 0;
  text-align: center;
}

.DraggablePlane .rotationHandle {
  position: absolute;
  width: 0;
  height: 0;
  display: none;
}

.DraggablePlane.selected .rotationHandle {
  display: block;
}


.DraggablePlane .rotationHandle>span {
  position: relative;
  display: block;
  width: 6px;
  height: 6px;
  background-color: white;
  border: 2px solid #286ff1;
}

.DraggablePlane .rotationHandle.ne {
  top: 0; 
  right: 0;
}

.DraggablePlane .rotationHandle.ne>span {
  left: -4px;
  top: -6px;
}

.DraggablePlane .rotationHandle.nw {
  top: 0; 
  left: 0;
}

.DraggablePlane .rotationHandle.nw>span {
  left: -6px;
  top: -6px;
}

.DraggablePlane .rotationHandle.se {
  bottom: 0; 
  right: 0;
}

.DraggablePlane .rotationHandle.se>span {
  left: -4px;
  top: -4px;
}

.DraggablePlane .rotationHandle.sw {
  bottom: 0; 
  left: 0;
}

.DraggablePlane.padded .rotationHandle.se {
  bottom: -20;
  right: -20;
}
.DraggablePlane.padded .rotationHandle.sw {
  bottom: -20;
  left: -20;
}
.DraggablePlane.padded .rotationHandle.ne {
  top: -20;
  right: -20;
}
.DraggablePlane.padded .rotationHandle.nw {
  top: -20;
  left: -20;
}

.DraggablePlane .rotationHandle.sw>span {
  left: -6px;
  top: -4px;
}

.DraggablePlane .rotationHandleHitbox {
  position: absolute;
  display: none;
  width: 50px;
  height: 50px;
  cursor: grab;
}

.DraggablePlane.padded .rotationHandleHitbox {
  width: 10px;
  height: 10px;
}


.DraggablePlane.rotating .rotationHandleHitbox {
  cursor: grabbing;
}

.DraggablePlane.selected .rotationHandleHitbox {
  display: block;
}

.DraggablePlane .rotationHandleHitbox.ne {
  top: -25px;
  right: -25px;
}

.DraggablePlane .rotationHandleHitbox.se {
  bottom: -25px;
  right: -25px;
}

.DraggablePlane .rotationHandleHitbox.sw {
  bottom: -25px;
  left: -25px;
}

.DraggablePlane .rotationHandleHitbox.nw {
  top: -25px;
  left: -25px;
}

.DraggablePlane .ClockwiseArrow,
.DraggablePlane .AntiClockwiseArrow {
  position: absolute;
  display: none;
}

.DraggablePlane.selected.rotating.rotatingClockwise .ClockwiseArrow {
  display: block;
}

.DraggablePlane.selected.rotating.rotatingAntiClockwise .AntiClockwiseArrow {
  display: block;
}

.DraggablePlane .ClockwiseArrow.ne, 
.DraggablePlane .AntiClockwiseArrow.ne
{
  top: -24px;
  right: -24px;
  transform: rotate(45deg);
}

.DraggablePlane .ClockwiseArrow.se,
.DraggablePlane .AntiClockwiseArrow.se {
  bottom: -24px;
  right: -24px;
  transform: rotate(135deg);
}

.DraggablePlane .ClockwiseArrow.sw,
.DraggablePlane .AntiClockwiseArrow.sw {
  bottom: -24px;
  left: -24px;
  transform: rotate(225deg);
}

.DraggablePlane .ClockwiseArrow.nw,
.DraggablePlane .AntiClockwiseArrow.nw {
  top: -24px;
  left: -24px;
  transform: rotate(315deg);
}


/* This exists externally to the parent .DraggablePlane class
 * so it needs a fully qualified namespaced CSS name.
 */
.DraggablePlane-SpacingBufferAircraftContainer {
  position: absolute;
  pointer-events: none;
  z-index: 1;
}